import React from "react";

import Logo from "../images/SG8-logo.png";
import Slot from "../images/slots-navigation.jpg";
import Slot1 from "../images/ld-navigation.jpg";
import Slot2 from "../images/sportsbook-navigation.jpg";
import Deposit from "../images/deposit-bonus.png";

const divStyle = {
  display: "none",
};

export default function Sidebar() {
  return (
    <>
      <section className="sidebar">
        <div className="logo">
          <img className="website-logo" alt="Logo" src={Logo} />
        </div>
        <div className="user-information">
          <div className="userwrapper">
            <span className="userID">SG8PNR750595</span>
            <span className="userBalance">10,000.00</span>
          </div>
          <div className="c2a-depo-withdraw">
            <button className="depo-btn">Deposit</button>
            <button className="withdraw-btn">Withdraw</button>
          </div>
          <div className="c2a-landingpage" style={divStyle}>
            <button className="login-btn">Login</button>
            <button className="reg-btn">Register</button>
          </div>
        </div>

        <div className="primary-menu-list">
          <div
            className="slots"
            style={{
              backgroundImage: `url(${Slot})`,
            }}
          >
            <a href="/promotion/">
              <span>Slots</span>
            </a>
          </div>
          <div
            className="slots"
            style={{
              backgroundImage: `url(${Slot1})`,
            }}
          >
            <a href="/">
              <span>Live Dealer</span>
            </a>
          </div>
          <div
            className="slots"
            style={{
              backgroundImage: `url(${Slot2})`,
            }}
          >
            <a href="/sportsbook/">
              <span>Sportsbook</span>
            </a>
          </div>
        </div>
        <div className="navarea-promotions">
          <img src={Deposit} alt="" />

          <a href="/index">
            <span>FULL T&amp;C</span>
          </a>
        </div>
      </section>
    </>
  );
}
