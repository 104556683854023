import React from "react";
import { Helmet } from "react-helmet";

export default function Promotion() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Discover and Enjoy Online Casino Bonus at SG8 Philippines</title>
      </Helmet>
      <div className="promotion">Promotion</div>
    </>
  );
}
