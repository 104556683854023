import React from "react";

export default function Footer() {
  return (
    <>
      <section className="footer">
        <h2>Who is SG8 Casino?</h2>
      </section>
    </>
  );
}
