import React from "react";
import MainPage from "./pages/MainPage";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
export default function Index() {
  return (
    <>
      <Sidebar />
      <MainPage />
      <Footer />
    </>
  );
}
