import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Promotion from "./modules/pages/Promotion";
import Index from "./modules/Index";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router>
      <Routes>
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/" element={<Index />} />
      </Routes>
    </Router>
    <App />
  </>
);
