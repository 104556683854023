import "./css/style.css";
import "./css/font.css";
import Sidebar from "./modules/Sidebar";
import MainPage from "./modules/pages/MainPage";

function App() {
  return (
    <>
      <Sidebar />
    </>
  );
}

export default App;
